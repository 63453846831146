import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import AmazonLogo from '../../../components/images/amazon';
import { IMG_Amazon } from '../../../components/images/docs/amazon';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <AmazonLogo />
            </div>
            <h2>
                Proceso de facturación
            </h2>
            <h3>
                Facturación a tu cliente 
            </h3>
            <p>
                En el caso particular de Amazon, <b>no es posible mandar Links por mensaje a tu comprador, ya que Amazon los elimina del mensaje.</b>
            </p>
            <p>
                Por ahora, el proceso de facturación es manual uno por uno, buscamos siempre hacer que este proceso sea lo más rápido y fluido posible, a medida que 
                el servicio con Amazon evolucione, haremos saber los nuevos procesos.  
            </p>
            <ImageFooted explanation={`El listado de tus pedidos mostrará el estado de tus facturas por pedido. El ícono de ticket de la derecha de cada renglón abrirá la facturación manual.`}>
                <IMG_Amazon file={`orderList`} />
            </ImageFooted>

            <SectionNavigate next={`/ecommerce/amazon/facturacion-manual`} previous={`/ecommerce/amazon/claves-default`} />
        </div>
    </Layout>
)

export default ShopifyClavesDefault;